import React from 'react';

import { HeroBanner, CaseHeader, TextContent, Gallery, GalleryLayout, Team } from 'modules/references';

import socialPreview from '../../../static/social-previews/kviff.png';
import Seo from '../../components/SEO';

import { ReferenceCategory } from '../../constants';

const CASE_ID = 'kviff';
const MODULE_NAME = 'kviff';
const IMAGE_PATH = `references/case-studies/${MODULE_NAME}`;

const colors = {
    bgLight: '#EBEDEF',
    bgDarkMain: '#121314',
    bgDarkSecondary: '#2B2C2E',
};

const heroGradient = `linear-gradient(180deg, ${colors.bgDarkSecondary} 0%, ${colors.bgDarkMain} 100%);`;

const team = [
    { department: 'references.case.team.management', names: ['Jan Mísař', 'Marek Király'] },
    {
        department: 'references.case.team.design',
        names: ['Ondřej Bartoš', 'Filip Hudák'],
    },
    {
        department: 'references.case.team.ux',
        names: ['Michal Mikolaj', 'Markéta Škaldová'],
    },
    {
        department: 'references.case.team.backend',
        names: ['Jiří Šmolík', 'Jiří Zdvomka', 'Dominik Veselý'],
    },
    {
        department: 'references.case.team.android',
        names: ['Petr Šíma', 'Jan Mottl'],
    },
    {
        department: 'references.case.team.ios',
        names: ['Alexandr Grigoryev', 'Jakub Olejník', 'Lukáš Hromadník'],
    },
    {
        department: 'references.case.team.testing',
        names: ['Anna Zmeevskaja', 'Mariya Vorona'],
    },
];

const KVIFF = () => (
    <>
        <Seo image={socialPreview} pageId={CASE_ID} />
        <CaseHeader />
        <HeroBanner
            content={{
                projectName: `case.${CASE_ID}.hero.project`,
                projectTitle: `case.${CASE_ID}.hero.title`,
                projectYear: `case.${CASE_ID}.hero.year`,
                client: `case.${CASE_ID}.hero.client`,
                image: {
                    src: `${IMAGE_PATH}/hero-image.png`,
                    style: { height: '70%', width: '100%', marginTop: '2rem' },
                    imgStyle: { objectFit: 'contain', objectPosition: 'center bottom' },
                },
            }}
            background={heroGradient}
            headerTheme="light"
            textColor="#fff"
        />
        <TextContent
            headerTheme="dark"
            content={{
                section: `case.${CASE_ID}.section1.name`,
                title: `case.${CASE_ID}.section1.title`,
                text: `case.${CASE_ID}.section1.text`,
                category: ReferenceCategory.DIGITALIZATION,
                outputs: [
                    'references.case.output.ux',
                    'references.case.output.design',
                    'references.case.output.android',
                    'references.case.output.ios',
                    'references.case.output.backend',
                    'references.case.output.testing',
                ],
            }}
        />
        <Gallery
            headerTheme="dark"
            layout={GalleryLayout.TWO_THIRDS}
            content={[
                {
                    background: colors.bgLight,
                    image: {
                        src: `${IMAGE_PATH}/02-1.png`,
                        style: {
                            height: '80%',
                            margin: 'auto',
                        },
                        imgStyle: {
                            objectFit: 'contain',
                            objectPosition: 'center',
                        },
                    },
                },
                {
                    background: colors.bgDarkMain,
                    image: {
                        src: `${IMAGE_PATH}/02-2.png`,
                        imgStyle: {
                            objectPosition: 'top',
                            objectFit: 'cover',
                        },
                    },
                },
            ]}
        />
        <TextContent
            headerTheme="dark"
            content={{
                section: `case.${CASE_ID}.section3.name`,
                title: `case.${CASE_ID}.section3.title`,
                text: `case.${CASE_ID}.section3.text`,
            }}
        />
        <Gallery
            headerTheme="dark"
            layout={GalleryLayout.EQUAL}
            content={[
                {
                    background: colors.bgLight,
                    image: {
                        src: `${IMAGE_PATH}/04-1.png`,
                        style: {
                            height: '85%',
                            margin: 'auto',
                        },
                        imgStyle: {
                            objectFit: 'contain',
                            objectPosition: 'center',
                        },
                    },
                },
                {
                    background: colors.bgLight,
                    image: {
                        src: `${IMAGE_PATH}/04-2.png`,
                        style: {
                            height: '85%',
                            margin: 'auto',
                        },
                        imgStyle: {
                            objectFit: 'contain',
                            objectPosition: 'center',
                        },
                    },
                },
                {
                    background: colors.bgLight,
                    image: {
                        src: `${IMAGE_PATH}/04-3.png`,
                        style: {
                            height: '85%',
                            margin: 'auto',
                        },
                        imgStyle: {
                            objectFit: 'contain',
                            objectPosition: 'center',
                        },
                    },
                },
            ]}
        />
        <TextContent
            headerTheme="dark"
            content={{
                section: `case.${CASE_ID}.section5.name`,
                title: `case.${CASE_ID}.section5.title`,
                text: `case.${CASE_ID}.section5.text`,
            }}
        />
        <Gallery
            headerTheme="dark"
            layout={GalleryLayout.ONE_THIRD}
            content={[
                {
                    background: colors.bgDarkMain,
                    image: {
                        src: `${IMAGE_PATH}/06-1.png`,
                        imgStyle: {
                            objectPosition: 'top',
                            objectFit: 'cover',
                        },
                    },
                },
                {
                    background: colors.bgDarkMain,
                    image: {
                        src: `${IMAGE_PATH}/06-2.png`,
                        style: {
                            height: '85%',
                            margin: 'auto',
                        },
                        imgStyle: {
                            objectFit: 'contain',
                            objectPosition: 'center',
                        },
                    },
                },
            ]}
        />
        <Gallery
            headerTheme="dark"
            layout={GalleryLayout.EQUAL}
            content={[
                {
                    background: colors.bgLight,
                    image: {
                        src: `${IMAGE_PATH}/07-1.png`,
                        style: {
                            height: '90%',
                            margin: 'auto',
                            marginBottom: 0,
                        },
                        imgStyle: {
                            objectFit: 'contain',
                            objectPosition: 'bottom',
                        },
                    },
                },
            ]}
        />
        <TextContent
            headerTheme="dark"
            content={{
                section: `case.${CASE_ID}.section8.name`,
                title: `case.${CASE_ID}.section8.title`,
                text: `case.${CASE_ID}.section8.text`,
            }}
        />
        <Gallery
            headerTheme="dark"
            layout={GalleryLayout.EQUAL}
            content={[
                {
                    background: colors.bgLight,
                    image: {
                        src: `${IMAGE_PATH}/09-1.png`,
                        style: {
                            height: '80%',
                            margin: 'auto',
                            marginBottom: 0,
                        },
                        imgStyle: {
                            objectFit: 'contain',
                            objectPosition: 'bottom center',
                        },
                    },
                },
            ]}
        />
        <Team headerTheme="dark" content={team} />
    </>
);

export default KVIFF;
